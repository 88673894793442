<template>
  <div class="page-container">
    <Filter ref="filterRef" :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="robotSn" label="机器人编码" />
      <el-table-column prop="cabinetSn" label="控制器编码" />
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column prop="updateTime" label="更新时间" />
      <el-table-column label="健康评分" width="100">
        <template #default="scope">
          <el-progress
            type="circle"
            :percentage="scope.row.score"
            class="progress-text"
            :color="scope.row.color"
             width="60"
          ></el-progress>
        </template>
      </el-table-column>
      <el-table-column label="健康趋势" width="100">
        <template #default="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="handleClick(scope.row)"
            >健康趋势</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
</template>

<script>
import { request } from '@/utils/request'
import { common } from '../../common/mixin'
import Table from '@/components/table/index'
import Filter from '@/components/table/filter'
import dayjs from 'dayjs'
export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  data () {
    return {
      params: [
        {
          key: 'robotSN',
          value: '',
          label: '机器人编码'
        },
        {
          key: 'cabinetSN',
          value: '',
          label: '控制柜编码'
        },
        {
          key: 'customerName',
          value: '',
          label: '客户名称'
        },
        {
          type: 'Date',
          key: 'dateRange',
          value: [
            dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00:00',
            dayjs().format('YYYY-MM-DD') + ' 23:59:59'
          ],
          label: '更新日期',
          options: {
            type: 'datetimerange',
            format: 'YYYY-MM-DD HH:mm:ss',
            'value-format': 'YYYY-MM-DD HH:mm:ss',
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
            'default-time': [
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59)
            ]
          }
        }
      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: []
    }
  },
  created () {},
  mounted () {
    this.$refs.filterRef?.searchData()
  },
  methods: {
    getData () {
      console.log(this.query)
      request('getHealthScoreList', {
        ...this.query,
        page: this.page,
        size: this.size,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1]
      })
        .then(data => {
          this.tableDataList = data.records
          this.total = parseInt(data.total)
          this.tableDataList.map(item => {
            if (item.score <= 0.2) {
              item.color = 'orange'
            } else if (0.2 < item.score < 0.8) {
              item.color = 'blue'
            } else {
              item.color = 'green'
            }
            item.score = item.score && item.score * 100
          })
        })
        .catch(error => {
          console.error(error)
        })
    },
    getDataMap () {
      try {
        request('getArea', {
          parentId: 1
        }).then(data => {
          this.provinceList = data
        })
        request('getRobotStatusMap').then(data => {
          this.robotStatusList = data
        })
        request('getIndustryList').then(data => {
          this.industryList = data
        })
      } catch (error) {
        console.log(error)
      }
    },
    searchData (data) {
      this.query = data
      this.getData()
    },
    updatePage (page, size) {
      this.page = page || 1
      this.size = size || 10
      this.getData()
    },
    handleClick (row) {
      this.$router.push({
        path: '/collect/healthScoreTrend',
        query: { robotSn: row.robotSn }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.progress-text {
  font-size: 12px;
}
</style>
