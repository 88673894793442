<template>
  <div class="page-container">
    <Table
      ref="tableRef"
      :params="params"
      :request="searchData"
      tooltip-effect="light"
    >
      <template #search>
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" @click="addNew"
            >新建</el-button
          >
        </el-form-item>
      </template>
      <template #toolBar>
        <el-input-number
          v-model="duration"
          controls-position="right"
          :disabled="realTime"
          :min="1"
          size="small"
          style="margin-right: 10px"
        />
        <el-switch
          v-model="realTime"
          active-text="实时更新"
          @change="onRealTimeChange"
        />
      </template>
      <el-table-column prop="taskName" label="任务名称" />
      <el-table-column prop="robotSN" label="机器人编码" />
      <el-table-column prop="cabinetSN" label="控制器编码" />
      <el-table-column prop="runDays" label="任务运行天数" />
      <el-table-column prop="runTimesPerDay" label="每天采集次数" />
      <el-table-column prop="maxRecordTime" label="单次采集时长" />
      <el-table-column prop="minTimeInterval" label="连续运行最小间隔" />
      <el-table-column prop="statusName" label="任务状态" />
      <el-table-column label="健康评分" width="100">
        <template #default="scope">
          <el-progress
            type="circle"
            :percentage="scope.row.score"
            class="progress-text"
            :color="scope.row.color"
            width="60"
          ></el-progress>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="任务创建时间" />
      <el-table-column label="操作" width="120" fixed="right">
        <template #default="{ row }">
          <el-button
            v-if="row.statusName == '执行中'"
            @click="terminateTask(row)"
            type="text"
            size="small"
            >提前终止</el-button
          >
        </template>
      </el-table-column>
    </Table>
    <el-dialog
      title="新增任务"
      top="5vh"
      v-model="dialogFormVisible"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
    >
      <el-form
        ref="detailformRef"
        class="dialog-form"
        :model="detailform"
        :rules="detailformRules"
        label-width="180px"
      >
        <el-form-item label="任务名称" prop="taskName">
          <el-input
            v-model="detailform.taskName"
            placeholder="任务名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="任务运行天数（天）" prop="runDays">
          <el-input
            v-model="detailform.runDays"
            placeholder="任务运行天数（范围1 ~ 365）"
            clearable
          />
        </el-form-item>
        <el-form-item label="每天执行次数（次）" prop="runTimesPerDay">
          <el-input
            v-model="detailform.runTimesPerDay"
            placeholder="每天执行次数（范围1 ~ 60）"
            clearable
          />
        </el-form-item>
        <el-form-item label="单次运行最长时间（s）" prop="maxRecordTime">
          <el-input
            v-model="detailform.maxRecordTime"
            placeholder="单次运行最长时间（范围30 ~ 150）"
            clearable
          />
        </el-form-item>
        <el-form-item label="连续运行最小间隔（s）" prop="minTimeInterval">
          <el-input
            v-model="detailform.minTimeInterval"
            placeholder="连续运行最小间隔（范围1 ~ 43200）"
            clearable
          />
        </el-form-item>
      </el-form>
      <el-button size="small" type="primary" @click="addRobot">添加</el-button>
      <el-table :data="robotDataList">
        <el-table-column prop="robotId" label="机器人编码" />
        <el-table-column prop="cabinetId" label="控制器编码" />
        <el-table-column label="机器人状态" width="100">
          <template #default="scope">
            {{ statusList[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template #default="{ row }">
            <i class="el-icon-delete" @click="deleteRobot(row)"></i>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button size="small" type="primary" @click="editSubmit"
          >确认</el-button
        >
      </template>
    </el-dialog>
    <el-dialog
      title="选择机器人"
      top="5vh"
      v-model="dialogTableVisible"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
    >
      <Filter
        ref="filterRef"
        :params="tableParams"
        @search="searchRobotData"
      ></Filter>
      <el-table
        ref="multipleTable"
        :data="tableRobotDataList"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :selectable="selectable" width="55">
        </el-table-column>
        <el-table-column prop="robotId" label="机器人编码" />
        <el-table-column prop="cabinetId" label="控制器编码" />
        <el-table-column label="机器人状态" width="100">
          <template #default="scope">
            {{ statusList[scope.row.status] }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 50, 200, 500]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="robotTotal"
      >
      </el-pagination>
      <template #footer>
        <el-button size="small" type="primary" @click="selectSubmit"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request } from '@/utils/request'
import { common } from '../../common/mixin'
// import Table from '@/components/table/index'
import Table from '@/components/elp/table'
import Filter from '@/components/table/filter'
import dayjs from 'dayjs'
import { reactive, toRefs } from '@vue/reactivity'
import { ElMessageBox, ElMessage } from 'element-plus'
import { robotStatusMap } from '../../common/constant'
import { intValidator } from '../../common/utils'

import { reqCollectTaskList } from '@/api/upgrade'

export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  setup () {
    const taskFields = [
      {
        label: '执行中',
        value: '执行中'
      },
      {
        label: '已结束',
        value: '已结束'
      },
      {
        label: '已终止',
        value: '已终止'
      },
      {
        label: '创建失败',
        value: '创建失败'
      }
    ]
    const state = reactive({
      realTime: false, // 是否实时更新
      timer: null, // 定时器
      duration: 10, // 时长
      tableRef: null,
      query: {
        otaUpgradeTimeRange: [
          dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00:00',
          dayjs().format('YYYY-MM-DD') + ' 23:59:59'
        ]
      },
      queryRobot: {},
      page: 1,
      size: 10,
      total: 0,
      robotTotal: 0,
      dialogFormVisible: false,
      dialogTableVisible: false,
      tableDataList: [],
      robotDataList: [],
      tableRobotDataList: [],
      detailformRef: null,
      detailform: {
        taskName: '',
        runDays: '',
        runTimesPerDay: '',
        maxRecordTime: '',
        minTimeInterval: '',
        robotList: []
      },
      params: [
        {
          key: 'robotSN',
          value: '',
          label: '机器人编码'
        },
        {
          key: 'cabinetSN',
          value: '',
          label: '控制柜编码'
        },
        {
          type: 'Select',
          key: 'statusName',
          value: null,
          label: '任务状态',
          options: {
            data: taskFields,
            label: 'label',
            value: 'value'
          }
        },
        {
          type: 'Date',
          key: 'otaUpgradeTimeRange',
          value: [
            dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00:00',
            dayjs().format('YYYY-MM-DD') + ' 23:59:59'
          ],
          label: '操作时间',
          options: {
            type: 'datetimerange',
            format: 'YYYY-MM-DD HH:mm:ss',
            'value-format': 'YYYY-MM-DD HH:mm:ss',
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
            'default-time': [
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59)
            ]
          }
        }
      ],
      tableParams: [
        {
          key: 'robotId',
          value: '',
          label: '机器人编码'
        }
      ],
      versionInfo: null,
      multipleSelection: [],
      statusList: robotStatusMap,
      toggleRowSelection: [],
      multipleTable: null
    })
    const detailformRules = reactive({
      taskName: {
        required: true,
        message: '请输入任务名称',
        trigger: 'change'
      },
      runDays: intValidator({
        label: '任务运行天数',
        min: 0,
        max: 365
      }),
      runTimesPerDay: intValidator({
        label: '每天执行次数',
        min: 0,
        max: 60
      }),
      maxRecordTime: intValidator({
        label: '单次运行最长时间',
        min: 30,
        max: 150
      }),
      minTimeInterval: intValidator({
        label: '连续运行最小间隔',
        min: 1,
        max: 43200
      })
    })

    const getRobotData = async () => {
      try {
        const res = await request('getCollectRobot', {
          ...state.queryRobot,
          page: state.page,
          size: state.size
        })
        state.tableRobotDataList = res.records
        state.toggleRowSelection = state.robotDataList
        state.robotDataList.map(row => {
          state.multipleTable.toggleRowSelection(
            state.tableRobotDataList.find(item => row.robotId == item.robotId),
            true
          )
        })
        state.robotTotal = res.total
      } catch (error) {
        console.error(error)
      }
    }

    const searchData = async params => {
      const taskList = reqCollectTaskList(params).then(res => {
        res.total = parseInt(res.total)
        res.size = parseInt(res.size)
        res.current = parseInt(res.current)
        res.records.map(item => {
          if (item.score <= 0.2) {
            item.color = 'orange'
          } else if (0.2 < item.score < 0.8) {
            item.color = 'blue'
          } else {
            item.color = 'green'
          }
          item.score = item.score && item.score * 100
        })
        return res
      })
      return taskList
    }

    const searchRobotData = async data => {
      state.queryRobot = data
      getRobotData()
    }

    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      state.tableRef?.update()
    }

    const updateRobotPage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getRobotData()
    }

    const addNew = () => {
      for (let key of Object.keys(state.detailform)) {
        state.detailform[key] = ''
      }
      state.dialogFormVisible = true
    }

    const deleteRobot = async row => {
      try {
        await ElMessageBox.confirm('确定删除？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        state.robotDataList = state.robotDataList.filter(item => {
          return item.robotId !== row.robotId
        })
        state.tableRef?.update()
        ElMessage({
          showClose: true,
          type: 'success',
          message: '删除成功!'
        })
      } catch (error) {
        console.error(error)
      }
    }

    const editSubmit = async () => {
      state.detailform.robotList = state.robotDataList.map(item => {
        item.cabinetSN = item.cabinetId
        item.robotSN = item.robotId
        return item
      })
      try {
        state.detailformRef && (await state.detailformRef.validate())
        const url = 'requestCreateTask'
        await request(url, state.detailform)
        state.dialogFormVisible = false
        state.tableRef?.update()
        ElMessage({
          showClose: true,
          type: 'success',
          message: '提交成功!'
        })
      } catch (error) {
        console.error(error)
      }
    }

    const selectSubmit = () => {
      const robotDataList = [...state.robotDataList, ...state.multipleSelection]
      const nowData = []
      robotDataList.filter(row => {
        const isDuplicate = nowData.find(item => item.robotId == row.robotId)
        if (!isDuplicate) {
          nowData.push(row)
        }
      })
      state.robotDataList = nowData
      state.dialogTableVisible = false
    }

    const addRobot = () => {
      state.dialogTableVisible = true
      getRobotData()
    }

    const selectable = row => {
      if (row.healthTaskStatus == '执行中') {
        return false
      } else {
        return true
      }
    }
    const handleSelectionChange = val => {
      state.multipleSelection = val
    }

    const terminateTask = async row => {
      try {
        await request('requestTerminateTask', {
          isDelete: 1,
          robotSN: row.robotSN,
          taskId: row.taskId
        })
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }

    const handleSizeChange = val => {
      state.size = val
      getRobotData()
    }

    const handleCurrentChange = val => {
      state.page = val
      getRobotData()
    }

    const onRealTimeChange = val => {
      if (val && state.duration) {
        state.timer = setInterval(() => {
          state.tableRef?.update()
        }, state.duration * 1000)
      } else {
        state.timer && clearInterval(state.timer)
      }
    }
    return {
      ...toRefs(state),
      handleSizeChange,
      handleCurrentChange,
      detailformRules,
      searchData,
      searchRobotData,
      updatePage,
      updateRobotPage,
      addNew,
      deleteRobot,
      editSubmit,
      addRobot,
      handleSelectionChange,
      selectable,
      selectSubmit,
      terminateTask,
      onRealTimeChange
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.el-icon-delete {
  cursor: pointer;
}
:v-deep(.el-progress-circle_track) {
  color: aquamarine;
}
</style>
